<template>
  <div>
    <b-modal
      centered
      scrollable
      :visible="activeJournalCategory !== null"
      @hide="$store.commit('activeJournalCategory', null)"
    >
      <template slot="modal-header">
        <h5 class="modal-title">
          {{ title }}
        </h5>

        <div>
          <button
            type="button"
            @click="print"
          >
            <img
              src="../assets/icons/printer.svg"
              alt
              class="icon"
            >
          </button>
          <button
            type="button"
            @click="$store.commit('activeJournalCategory', null)"
          >
            <img
              src="../assets/icons/close.svg"
              alt
              class="icon"
            >
          </button>
        </div>
      </template>

      <div>
        <div
          v-if="status == 'loading'"
          class="loading"
        >
          Loading <spinner color="var(--primary)" />
        </div>
        <template v-else-if="status == 'loaded'">
          <template v-if="entries.length">
            <div
              v-for="(entry, index) of entries"
              :key="`entry${index}`"
              class="entry"
            >
              <div class="entry-top">
                <h6>{{ entry.label }}</h6>

                <span class="entry-date">
                  {{ formatTimestamp(entry.timestamp) }}
                </span>
              </div>

              <div v-html="linebreaksbr(entry.user_response)" />
            </div>
          </template>
          <div
            v-else
            class="no-entries"
          >
            You do not have any entries in this portion of your journal yet. As
            you progress through the program, entries will
            automatically appear here.
          </div>
        </template>
        <div
          v-else-if="status == 'error'"
          class="error"
        >
          <div
            class="alert alert-danger"
            role="alert"
          >
            <p>
              Unable to communicate with the server. Please check your connection
              and try again.
            </p>

            <button
              type="button"
              class="btn btn-light"
              @click="loadData"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>

      <div slot="modal-footer" />
    </b-modal>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'Journal',
  components: {
    Spinner,
  },
  data() {
    return {
      status: 'loading',
      title: '',
      entries: [],
      slugMap: {
        belief: 'Belief',
        essence: 'Essence',
        how: 'The How',
        target: 'The Target',
      },
    };
  },
  computed: {
    activeJournalCategory() {
      return this.$store.state.activeJournalCategory;
    },
    activeJournalProgram() {
      return this.$store.state.activeJournalProgram;
    },
    userData() {
      return this.$store.state.userData;
    },
  },
  watch: {
    activeJournalCategory() {
      if (this.activeJournalCategory !== null) {
        this.title = `Your Journal: ${this.slugMap[this.activeJournalCategory]}`;
        this.loadData();
      }
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleDateString();
    },
    async loadData() {
      this.status = 'loading';

      const response = await fetch(
        `${process.env.VUE_APP_API_URL}programs/8_weeks_to_be/journal/?category=${this.activeJournalCategory}&program=${this.activeJournalProgram}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${this.userData.drf_token}`,
          },
        },
      );

      if (response && [200, 401].includes(response.status)) {
        if (response.status === 200) {
          this.entries = await response.json();
          this.status = 'loaded';
        } else {
          this.logUserOut();
        }
      } else {
        this.status = 'error';
      }
    },
    print() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-header button {
    border: 0;
    background-color: transparent;
    padding: 0;

    + button {
      margin-left: 1rem;

      .icon {
        width: 14px;
      }
    }
  }

  /deep/ .modal-body {
    padding: 0;
  }

  .loading,
  .error,
  .no-entries {
    padding: 2.5rem;
  }

  .loading {
    text-align: center;
  }

  .error .alert {
    margin-bottom: 0;
  }

  .entry {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    + .entry {
      border-top: 1px solid #e6e6e6;
    }
  }

  .entry-top {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr auto;
  }

  .entry-date {
    font-size: 0.8rem;
  }

  @media screen {
    .entry {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }

    .entry-date {
      color: #c9c9c9;
    }
  }
</style>
