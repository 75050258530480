import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeJournalCategory: null,
    activeJournalProgram: null,
    latestFromRoute: { matched: [] },
    userData: JSON.parse(localStorage.getItem('userData')),
    programs: JSON.parse(localStorage.getItem('programs')),
  },
  mutations: {
    activeJournalCategory(state, value) {
      state.activeJournalCategory = value;
    },
    activeJournalProgram(state, value) {
      state.activeJournalProgram = value;
    },
    latestFromRoute(state, value) {
      state.latestFromRoute = value;
    },
    userData(state, value) {
      state.userData = value;
      localStorage.setItem('userData', JSON.stringify(value));
    },
    programs(state, value) {
      state.programs = value;
      localStorage.setItem('programs', JSON.stringify(value));
    },
  },
  actions: {
  },
  modules: {
  },
});
