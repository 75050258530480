<template>
  <transition>
    <div class="spinner-container">
      <svg
        v-show="show"
        :class="{ show: show }"
        class="spinner"
        width="44px"
        height="44px"
        viewBox="0 0 44 44"
      >
        <circle
          :stroke="color"
          class="path"
          fill="none"
          stroke-width="4"
          stroke-linecap="round"
          cx="22"
          cy="22"
          r="20"
        />
      </svg>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    color: {
      type: String,
      required: false,
      default: '#fff',
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$offset: 126;
$duration: 1.4s;

.spinner-container {
  display: inline-block;
  width: 0.82em;
  height: 0.82em;
  line-height: 1;
}

.spinner {
  max-width: 100%;
  max-height: 100%;
  transition: opacity .15s ease;
  animation: rotator $duration linear infinite;
  animation-play-state: paused;

  &.show {
    animation-play-state: running;
  }

  &.v-enter,
  &.v-leave-active {
    opacity: 0;
  }

  &.v-enter-active,
  &.v-leave {
    opacity: 1;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.spinner .path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: ($offset / 2);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
</style>
