import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { allowAnonymous: true },
    component: () => import('../views/users/Login.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
    children: [
      {
        path: '',
        name: 'main',
        component: () => import('../views/Main.vue'),
      },
      {
        path: '/welcome/:version?',
        name: 'welcome',
        props: true,
        component: () => import('../views/users/Welcome.vue'),
      },
      {
        path: '/program/living_in_excellence',
        name: 'living_in_excellence',
        props: true,
        component: () => import('../views/programs/LivingInExcellence.vue'),
      },
      {
        path: '/enroll-new',
        name: 'enroll_new',
        component: () => import('../views/users/EnrollNew.vue'),
      },
      {
        path: '/program/:id/cancel',
        name: 'program_cancel',
        props: true,
        component: () => import('../views/programs/Cancel.vue'),
      },
      {
        path: '/edit_account',
        name: 'edit_account',
        component: () => import('../views/users/EditAccount.vue'),
      },
      {
        path: '/manage-programs',
        name: 'manage_programs',
        component: () => import('../views/users/ManagePrograms.vue'),
      },
      {
        path: '/program/:id/update_payment_method',
        name: 'update_payment_method',
        props: true,
        component: () => import('../views/users/UpdatePaymentMethod.vue'),
      },
      {
        path: '/journals',
        name: 'journals',
        component: () => import('../views/programs/Journals.vue'),
      },
      {
        path: '/ask-a-coach',
        name: 'ask_a_coach',
        component: () => import('../views/AskACoach.vue'),
      },
      {
        path: '/store-and-media',
        name: 'store_and_media',
        component: () => import('../views/StoreAndMedia.vue'),
      },
      {
        path: '/page/:slug',
        name: 'flatpage',
        props: true,
        component: () => import('../views/Flatpage.vue'),
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('../views/users/Logout.vue'),
      },
    ],
  },
  {
    path: '/program/:id',
    name: 'program',
    props: true,
    component: () => import('../views/programs/Program.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { allowAnonymous: true },
    component: () => import('../views/users/Login.vue'),
  },
  {
    path: '/password_reset',
    name: 'password_reset',
    meta: { allowAnonymous: true },
    component: () => import('../views/users/PasswordReset.vue'),
  },
  {
    path: '/password_reset/:uidb64/:token/',
    name: 'password_reset_confirm',
    meta: { allowAnonymous: true },
    props: true,
    component: () => import('../views/users/PasswordResetConfirm.vue'),
  },
  {
    path: '/register',
    redirect: { name: 'enroll' },
  },
  {
    path: '/enroll',
    name: 'enroll',
    meta: { allowAnonymous: true },
    component: () => import('../views/users/Enroll.vue'),
  },
  {
    path: '*',
    name: 'page_not_found',
    meta: { allowAnonymous: true },
    component: () => import('../views/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('latestFromRoute', from);

  if (to.matched.some(record => record.meta.allowAnonymous)) {
    next();
  } else if (store.state.userData === null) {
    next({
      name: 'login',
      query: { next: 'profile' },
    });
  } else {
    next();
  }
});

export default router;
