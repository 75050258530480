<template>
  <div
    id="app"
    :class="'logged-' + (userData ? 'in' : 'out') + (activeJournalCategory === null ? '' : ' journal-open')"
  >
    <template v-if="userData">
      <router-view />
      <journal />
    </template>
    <div
      v-else
      class="container"
    >
      <img
        src="./assets/logo-2.png"
        alt="To Be Legacy"
        class="logo"
      >

      <b-nav
        pills
        align="center"
      >
        <b-nav-item
          :to="{name: 'login'}"
          active-class="active"
        >
          Log In
        </b-nav-item>

        <b-nav-item
          :to="{name: 'enroll'}"
          active-class="active"
        >
          Enroll
        </b-nav-item>
      </b-nav>

      <router-view />
    </div>
  </div>
</template>

<script>
import Journal from '@/components/Journal.vue';

export default {
  components: {
    Journal,
  },
  computed: {
    activeJournalCategory() {
      return this.$store.state.activeJournalCategory;
    },
    userData() {
      return this.$store.state.userData;
    },
  },
  metaInfo: {
    title: 'To Be Legacy',
  },
};
</script>

<style lang="scss">
// Color system
$primary: rgb(25, 132, 248);
$info: #21c3bd;
$gradient-dark-blue: #004d9a;
$gradient-teal: #2defda;

// Gradients
$teal-dark-blue: linear-gradient(0deg, #{$gradient-dark-blue} 0%, #{$gradient-teal} 100%);
$dark-blue-teal: linear-gradient(0deg, #{$gradient-teal} 0%, #{$gradient-dark-blue} 100%);

// Body
$body-color: #424140;

// Links
$link-color: #87cefc;
$link-decoration: underline;
$link-hover-color: $primary;
$link-hover-decoration: none;

// Typography
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-weight-base: 300;
$h1-font-size: 1.9rem;
$h2-font-size: 1.8rem;

// Buttons + Forms
$input-btn-padding-y-lg: 14px;
$input-btn-padding-x-lg: 40px;
$input-btn-font-size-lg: 14px;

// Buttons
$btn-border-width: 0;
$btn-font-weight: 600;

// Navbar
$navbar-nav-link-padding-x: 0.94rem;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: #fff;

// Dropdowns
$dropdown-font-size: 14px;

// Modals
$modal-inner-padding: 2.5rem;
$modal-content-bg: transparent;
$modal-content-border-width: 0;
$modal-backdrop-opacity: 0.85;
$modal-footer-border-width: 0;
$modal-header-padding-x: 0;

// Progress bars
$progress-height: 0.8rem;
$progress-border-radius: 0.5rem;

@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-vue/src/index.scss';

:root {
  --header-height: 111px;
  --bottom-bar-height: 100px;
}

html, body, #app {
  height: 100%;
  width: 100%;
}

h1 {
  text-align: center;
  margin: 50px 0px;
  font-weight: bold;
}

a {
  transition: .1s all ease-in-out;
}

form {
  width: 430px;
  max-width: 100%;
  margin: 0 auto;
}

.alert p:last-child {
  margin-bottom: 0;
}

.btn {
  text-transform: uppercase;

  + .btn {
    margin-left: 1rem;
  }
}

.stripe-payment-form:after {
  content: '';
  display: block;
  height: 1rem;
}

.background-primary {
  background-color: rgb(25, 132, 248);
}

.background-gradient {
  background-image: linear-gradient(0deg, rgb(49, 207, 215) 0%, rgb(25, 132, 248) 100%);
}

.btn-primary {
  background-image: linear-gradient(90deg, rgb(49, 207, 215) 0%, rgb(25, 132, 248) 100%);

  &:not(:disabled) {
    &:focus,
    &:hover {
      background-image: linear-gradient(90deg, rgb(25, 132, 248) 0%, rgb(25, 132, 248) 100%);
    }
  }
}

.modal-backdrop {
  background-image: linear-gradient(0deg, rgb(49, 207, 215) 0%, rgb(25, 132, 248) 100%);
}

.modal-title {
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
}

.modal-body {
  font-size: 0.875rem;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  word-break: break-word;

  p:last-child {
    margin-bottom: 0;
  }
}

.modal-footer {
  margin-top: -3px;
  padding: 0;
  justify-content: center;
}

.icon {
  width: 21px;

  .btn & {
    margin-left: -10px;
    margin-right: 5px;
    vertical-align: bottom;
  }
}

.profile {
  margin: 0 auto;
}

.sidebar {
  position: fixed;
  padding: 1rem 0;
  width: 268px;
  height: 100vh;
  overflow-y: auto;
  background-image: #{$teal-dark-blue};
  color: white;

  .nav .nav-item {
    cursor: pointer;

    .nav-link {
      color: white;
      font-weight: 500;
      text-decoration: none;

      &.active {
        color: var(--primary);
        font-weight: 600;
        background-color: white;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        position: relative;
      }

      &:hover {
        font-weight: 600;
      }
    }
  }
}

.mobile-navbar {
  display: none;

  .nav .nav-item {
    cursor: pointer;

    .nav-link {
      color: white;
      text-decoration: none;
      font-weight: 500;

      &.active {
        color: var(--primary);
        font-weight: 600;
        background-color: white;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        position: relative;
      }

      &:hover {
        font-weight: 600;
      }
    }
  }
}

.main-header {
  position: sticky;
  top: 0;
  display: grid;
  height: var(--header-height);
  background-color: #fff;
  border-bottom: 1px solid $border-color;
  z-index: 1;

  .navbar {
    margin-left: auto;
    padding: 0;
  }

  .navbar-nav {
    .dropdown-toggle {
      height: 100%;
      text-decoration: none;
      font-size: 0.87rem;
      font-weight: 400;

      &:focus,
      &:hover {
        fill: $primary;
      }
    }

    .icon {
      margin-right: 10px;
      transition: .1s fill ease-in-out;
    }

    .dropdown-menu {
      margin: 0;
      min-width: 100%;

      &:before {
        content: '';
        position: absolute;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent #fff transparent;
      }
    }

    .dropdown-item {
      text-decoration: none;
    }
  }

  .nav-item.show {
    .dropdown-toggle {
      background-image: linear-gradient(0deg, rgba(135,206,252,1) 0%, rgba(111,147,227,1) 100%);
    }

    .icon {
      fill: $navbar-light-active-color;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
  }

  .profile-img {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    border: 2px solid $body-color;
  }

  .first-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.logged-out {
  display: grid;
  align-items: center;
  padding: 15px 0;
  min-height: 100vh;
  background-color: #f5f5f5;

  .container {
    max-width: 100vw;
    background-color: #f5f5f5;
  }

  .logo {
    display: block;
    margin: 0 auto 1.5rem;
    width: 230px;
  }

  .nav {
    margin-bottom: 1.5rem;
  }

  .nav-item + .nav-item {
    margin-left: 1rem;
  }

  .nav-link {
    text-decoration: none;
  }
}

.logged-in {
  .logo {
    margin: 1.5rem 15px;
    width: 130px;
  }
}

.content {
  @include make-container();
  padding-top: 15px;
  padding-bottom: 15px;
}

.video-container {
  position: relative;
  margin: 1rem 0;
  padding-bottom: 56.25%;
  max-width: 100%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .main-header {
    grid-template-columns: auto 1fr;

    .navbar-nav {
      height: 100%;
    }

    .nav-item-user {
      min-width: 9.5rem;
      max-width: 12rem;
    }
  }

  main {
    margin-left: 268px;
  }
}

@media (max-width: 1199.98px) {
  .btn .icon {
    margin-left: 0;
    margin-right: -3px;
  }
}

@media (max-width: 1023.98px) {
  :root {
    --header-height: 150px;
  }

  .sidebar {
    display: none;
  }

  .mobile-navbar {
    display: block;
  }

  .main-header {
    grid-template-rows: 1fr auto;

    .navbar,
    .navbar-nav {
      width: 100%;
    }

    .nav-item {
      width: 50%;
    }

    .nav-link {
      justify-content: center;
    }
  }

  .logged-in {
    h1 {
      width: 100%;
      font-size: 1.4rem;
    }

    .logo {
      display: block;
      margin: 6px auto;
      width: 170px;
    }
  }
}

@media (max-width: 567.98px) {
  :root {
    --bottom-bar-height: 120px;
  }
}

@media (max-width: 374.98px) {
  .main-header {
    .nav-item:first-child {
      width: 45%;
    }

    .nav-item-user {
      width: 55%;
      max-width: 12rem;
    }
  }
}

@media print {
  .journal-open {
    display: none;

    ~ div {
      width: 100%;

      .modal {
        padding-left: 0 !important;
        position: static;
      }

      .modal-dialog {
        display: block;
        margin: 0;
        max-width: none;
        min-height: auto;

        &:before {
          height: auto;
        }
      }

      .modal-header {
        padding-top: 0;

        button {
          display: none;
        }
      }

      .modal-title {
        color: $body-color;
      }

      .modal-body {
        padding: 0;
        box-shadow: none;
      }

      .modal-backdrop {
        display: none;
      }
    }
  }
}
</style>
