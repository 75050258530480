export default function goBack(fallbackRoute = 'home') {
  if (this.$store.state.latestFromRoute.matched.length) {
    this.$router.go(-1);
  } else {
    if (typeof fallbackRoute === 'string') {
      fallbackRoute = { name: fallbackRoute };
    }

    this.$router.push(fallbackRoute);
  }
}
