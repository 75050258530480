import router from '../router';

export default function logUserOut(store) {
  if (store === undefined) {
    store = this.$store;
  }

  store.commit('userData', null);
  router.push({ name: 'login' });
}
