import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { BootstrapVue /* BootstrapVueIcons */ } from 'bootstrap-vue';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import globalMethods from './global_methods';
import router from './router';
import store from './store';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://9404f5154e7343db97088deb5ef2bd2d@errors.danemco.net/67',
    integrations: [new Integrations.Vue({ Vue })],
  });
} else {
  console.log('Not using Sentry.'); // eslint-disable-line no-console
}

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons);
Vue.use(VueMeta);

Vue.mixin({ methods: globalMethods });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
